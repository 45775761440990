<template>
  <div>
    <vx-card class="mb-base no-shadow">
      <transition name="fade" mode="out-in">
        <vs-button v-if="showForm" @click="onCreate">{{ $t("Back") }}</vs-button>
        <div class="mb-base">
          <data-table
            class="datatablePayments"
            v-if="!showForm"
            :data="tableData"
            :options="tableOptions"
            :exportRequest="exportRequest"
            @sort="onSort"
            @search="onSearch"
            @change-page="onTablePageChange"
            @per-page-change="onPerPageChange"
            @date-range-search="onDateRangeSearch"
            @date-range-clear="onDateRangeClear"
          />
        </div>
      </transition>
    </vx-card>
  </div>
</template>

<script>
import DataTable from "@/modules/Shared/Components/table/DataTable";
import PaymentForm from "@/modules/Shops/Pages/forms/PaymentForm.vue";
import notifications from "@/modules/Shared/Mixins/notifications.js";
import table_functions from "@/modules/Shared/Mixins/table_functions.js";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Payments",

  mixins: [notifications, table_functions],

  components: {
    DataTable,
    PaymentForm,
  },

  props: {
    standalone: { type: Boolean, default: true },
    onlyForm: { type: Boolean, default: false },
  },

  data() {
    return {
      showForm: false,
      formData: {},
      defaultSort: "-created_at",
      exportRequest: {},
      exportPayload: {}
    };
  },

  computed: {
    ...mapGetters("shops/payments", {
      paymentsList: "getAll",
      totalPages: "getTotalPages",
      currentPage: "getCurrentPage",
      perPage: "getPerPage",
      paymentById: "getById",
    }),

    tableData() {
      return this.paymentsList.map(this.paymentToTableData);
    },

    tableOptions() {
      return {
        actions: {
          create: false,
          update: false,
          delete: false,
        },
        pagination: {
          currentPage: this.currentPage,
          totalPages: this.totalPages,
          itemsPerPage: this.perPage,
        },
        filters: {
          dateRange: true,
        },
      };
    },
  },

  methods: {
    ...mapActions("shops/payments", { loadData: "load" }),

    paymentToTableData(payment) {
      return {
        customer: payment.payer_name,
        customer_email: payment.payer_email,
        order_reference: payment.order_reference,
        transaction_code: payment.transaction_code,
        info: payment.info,
        amount: payment.amount,
        payment_gateway: payment.payment_gateway_name,
        created_at: payment.created_at
      };
    },

    default() {
      return {
        id: 0,
        info: "",
        transaction_code: "",
        amount: 0.0,
        method: "",
        payment_gateway_name: "",
      }
    }
  },

  created() {
    this.formData = this.default()
    this.exportPayload.sort = this.defaultSort;

    this.exportRequest = {
      request: `resorts/${this.$route.params.uuid}/payments_export`,
      payload: this.exportPayload
    };
    this.$store.commit("shops/payments/RESET_RESOURCE");
  },
};
</script>

<style>
</style>
