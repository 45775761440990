var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "price-form" }, [
    _c("div", { staticClass: "vx-row mb-6" }, [
      _c(
        "div",
        { staticClass: "vx-col w-1/4" },
        [
          _c("label", { staticClass: "vs-input--label" }, [
            _vm._v(_vm._s(_vm.$t("Info")))
          ]),
          _c("vs-input", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: "required",
                expression: "'required'"
              }
            ],
            staticClass: "w-full",
            attrs: {
              name: "info",
              danger: _vm.errors.has("info"),
              "danger-text": _vm.$t("shops.errors.info_is_required")
            },
            model: {
              value: _vm.value.info,
              callback: function($$v) {
                _vm.$set(_vm.value, "info", $$v)
              },
              expression: "value.info"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-1/4" },
        [
          _c("label", { staticClass: "vs-input--label" }, [
            _vm._v(_vm._s(_vm.$t("Transaction code")))
          ]),
          _c("vs-input", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: "required",
                expression: "'required'"
              }
            ],
            staticClass: "w-full",
            attrs: {
              name: "transaction_code",
              danger: _vm.errors.has("transaction_code"),
              "danger-text": _vm.$t("shops.errors.transaction_code_is_required")
            },
            model: {
              value: _vm.value.transaction_code,
              callback: function($$v) {
                _vm.$set(_vm.value, "transaction_code", $$v)
              },
              expression: "value.transaction_code"
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "vx-col" }, [
        _c(
          "div",
          { staticClass: "w-1/1" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v(_vm._s(_vm.$t("Amount")))
            ]),
            _c("vs-input-number", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              staticClass: "w-full",
              attrs: {
                name: "amount",
                danger: _vm.errors.has("amount"),
                "danger-text": _vm.$t("shops.errors.amount_is_required")
              },
              model: {
                value: _vm.value.amount,
                callback: function($$v) {
                  _vm.$set(_vm.value, "amount", $$v)
                },
                expression: "value.amount"
              }
            })
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "vx-col w-1/4" },
        [
          _c("label", { staticClass: "vs-input--label" }, [
            _vm._v(_vm._s(_vm.$t("Method")))
          ]),
          _c("vs-input", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: "required",
                expression: "'required'"
              }
            ],
            staticClass: "w-full",
            attrs: {
              name: "method",
              danger: _vm.errors.has("method"),
              "danger-text": _vm.$t("shops.errors.method_is_required")
            },
            model: {
              value: _vm.value.method,
              callback: function($$v) {
                _vm.$set(_vm.value, "method", $$v)
              },
              expression: "value.method"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-1/3" },
        [
          _c(
            "vs-button",
            {
              staticClass: "mt-base",
              attrs: { type: "filled" },
              on: {
                click: function($event) {
                  return _vm.validateAndSubmit(_vm.value)
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("shops.save")))]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }