<template>
  <div class="price-form">
    <div class="vx-row mb-6">
      <div class="vx-col w-1/4">
        <label class="vs-input--label">{{ $t("Info") }}</label>
        <vs-input
          class="w-full"
          v-model="value.info"
          v-validate="'required'"
          name="info"
          :danger="errors.has('info')"
          :danger-text="$t('shops.errors.info_is_required')"
        />
      </div>
      <div class="vx-col w-1/4">
        <label class="vs-input--label">{{ $t("Transaction code") }}</label>
        <vs-input
          class="w-full"
          v-model="value.transaction_code"
          v-validate="'required'"
          name="transaction_code"
          :danger="errors.has('transaction_code')"
          :danger-text="$t('shops.errors.transaction_code_is_required')"
        />
      </div>
      <div class="vx-col">
        <div class="w-1/1">
          <label class="vs-input--label">{{ $t("Amount") }}</label>
          <vs-input-number
            class="w-full"
            v-model="value.amount"
            v-validate="'required'"
            name="amount"
            :danger="errors.has('amount')"
            :danger-text="$t('shops.errors.amount_is_required')"
          />
        </div>
      </div>
      <div class="vx-col w-1/4">
        <label class="vs-input--label">{{ $t("Method") }}</label>
        <vs-input
          class="w-full"
          v-model="value.method"
          v-validate="'required'"
          name="method"
          :danger="errors.has('method')"
          :danger-text="$t('shops.errors.method_is_required')"
        />
      </div>
      <div class="vx-col w-1/3">
        <vs-button @click="validateAndSubmit(value)" class="mt-base" type="filled">{{ $t("shops.save") }}</vs-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import notifications from "@/modules/Shared/Mixins/notifications.js";
import i18n from "@/i18n";

const defaultPayment = () => ({
  id: 0,
  info: "",
  transaction_code: "",
  amount: 0.0,
  method: "",
  payment_gateway_name: ""
});

export default {
  name: "PriceFrom",

  props: {
    value: { type: Object, default: () => defaultPayment() }
  },

  mixins: [notifications],

  data() {
    return {};
  },

  computed: {
    ...mapGetters("shops/products", { paymentsList: "getAll" })
    // ...mapGetters("shops/participants", { participantsList: "getAll" }),
    // ...mapGetters("shops/periods", { periodsList: "getAll" })
  },

  methods: {
    ...mapActions("shops/products", { loadPaymentsList: "load" }),
    // ...mapActions("shops/participants", { loadParticipantsList: "load" }),
    // ...mapActions("shops/periods", { loadPeriodsList: "load" }),

    async validateAndSubmit(value) {
      const result = await this.$validator.validateAll();

      if (result) {
        this.$emit("submit", value);
      } else {
        this.notifyWarning(i18n.t("shops.warning"), i18n.t("shops.price_validation_failed"));
        console.warn(result);
      }
    }
  },

  created() {
    this.loadPaymentsList({ page_size: 1000 });
    // this.loadParticipantsList({ page_size: 1000 });
    // this.loadPeriodsList({ page_size: 1000 });
  }
};
</script>

<style>
</style>
