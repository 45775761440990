var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vx-card",
        { staticClass: "mb-base no-shadow" },
        [
          _c(
            "transition",
            { attrs: { name: "fade", mode: "out-in" } },
            [
              _vm.showForm
                ? _c("vs-button", { on: { click: _vm.onCreate } }, [
                    _vm._v(_vm._s(_vm.$t("Back")))
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "mb-base" },
                [
                  !_vm.showForm
                    ? _c("data-table", {
                        staticClass: "datatablePayments",
                        attrs: {
                          data: _vm.tableData,
                          options: _vm.tableOptions,
                          exportRequest: _vm.exportRequest
                        },
                        on: {
                          sort: _vm.onSort,
                          search: _vm.onSearch,
                          "change-page": _vm.onTablePageChange,
                          "per-page-change": _vm.onPerPageChange,
                          "date-range-search": _vm.onDateRangeSearch,
                          "date-range-clear": _vm.onDateRangeClear
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }